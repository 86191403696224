@mixin Littlemobile {
  @media screen and (max-width: 321px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (min-width: 321px) and (max-width: 446px) {
    @content;
  }
}

@mixin LGmobile {
  @media screen and (min-width: 446px) and (max-width: 768px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) and (max-width: 1020px) {
    @content;
  }
}

@mixin LGtablet {
  @media screen and (min-width: 1020px) and (max-width: 1140px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1020px) {
    @content;
  }
}



.section {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  // background-color: #000;
  background-color: #31859C;
  flex-wrap: wrap;
  min-height: 100vh;
}

.container {
  margin: auto;
  background: 0 0;
  padding: 50px 0 160px;
}

.form_container {
  // background-color: #15172b;
  background-color: #f2f2f2;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 20px;
}

.title {
  // color: #eee;
  color: black;
  font-family: sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin-top: 10px;
}

.subtitle {
  // color: #eee;
  color: black;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.input-container {
  height: 50px;
  position: relative;
  width: 100%;
}

.ic1 {
  margin-top: 40px;
}

.ic2 {
  margin-top: 30px;
}

.input-text {
  // background-color: #303245;
  background-color: #D9D9D9;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  // color: #eee;
  color: black;
  font-size: 18px;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
}

.cut {
  // background-color: #15172b;
  background-color: #F2F2F2;
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 76px;
}


.input-text:focus ~ .cut,
.input-text:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.placeholder {
  // color: #65657b;
  color: black;
  font-family: sans-serif;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}

.input-text:focus ~ .placeholder,
.input-text:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.input-text:not(:placeholder-shown) ~ .placeholder {
  color: #808097;
}

.input-text:focus ~ .placeholder {
  color: #60dc2f;
}

select {
  // background-color: #303245;
  background-color: #D9D9D9;
  border-radius: 12px;
  border: 0;
  // color: #65657b;
  color: black;
  font-size: 18px;
  height: 100%;
  outline: 0;
  padding: 4px 15px 0;
  width: 100%;
}

.cut_select {
  // background-color: #15172b;
  background-color: #F2F2F2;
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -10px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 76px;
  display: none;
  /* display: block; */
}

.placeholder_select {
  color: #60dc2f;
  font-family: sans-serif;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
  transform: translateY(-30px) translateX(10px) scale(0.75);
  display: none;
}

.display {
  display: block !important;
  // color: #60dc2f;
}

.hide {
  display: none !important;
}

.submit {
  // background-color: #08d;
  background-color: #93CDDD;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  // color: #eee;
  color: black;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 38px;
  text-align: center;
  width: 100%;
}

.submit:active {
  // background-color: #06b;
  background-color: #93CDDD;
}

.h100 {
  height: 100px;
}

.error {
  border: solid 4px #dc2f55;
  border-radius: 17px;
}

.error_text {
  color: #dc2f55;
  font-family: sans-serif;
  text-align: center;
  margin: 0;
  top: 33px;
  position: relative;
}

.msg_success {
  // color: #eee;
  color: black;
  font-family: sans-serif;
  font-size: 16px;
  text-align: center;
  position: relative;
  top: 45px;
  margin-top: 0;
}

.input_file {
  display: none;
}

.label_file {
  cursor: pointer;
  // background-color: #303245;
  background-color: #93CDDD;
  border-radius: 8px;
  border: 0;
  box-sizing: border-box;
  height: 25px;
  outline: 0;
  padding: 0px 20px 0;
  // color: #65657b;
  color: black;
}

.input_file_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
  flex-direction: column;
}

.label_file_length {
  // color: #eee;
  color: black;
}

.info {
  margin-left: 5px;
  // color: #eee;
  color: black;
  height: 70px;
}

.ic3 {
  margin-top: 117px;
  @include Littlemobile {
    margin-top: 117px;
  }
  @include mobile {
    // margin-top: 80px;
    margin-top: 105px;
  }
  @include LGmobile {
    margin-top: 76px;
  }
  @include tablet {
    margin-top: 30px;
  }
  @include desktop {
    margin-top: 20px;
  }
}


.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #555;
  /* border: 5px solid #D9D9D9;
  border-radius: 50%;
  border-top: 5px solid #93CDDD; */
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loaderFullcontainer {
  height: 100vh;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  left: 0px;
  background-color: #c1c1c1;
}

.loaderFull {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  width: 100%;
  top: 0;
}

.loader-txt {
  margin-top: 30px;
  color: #555;
}